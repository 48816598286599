import React, { useState, useEffect } from 'react'
import { phoneNumber, zapierLink } from '../content/content.json'
import CirclePhoneIcon from './icons/CirclePhoneIcon'
import EmailValidator from 'email-validator'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { navigate } from 'gatsby'
import Swal from 'sweetalert2'
import codePostalLookup from '../data/CodePostalLookup'

const alertStyle = {
    color: "rgba(255, 0, 0, 0.7)",
    fontSize: 12,
    marginTop: 5,
}

const CTAForm = ({codePostal = ""}) => {
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [telephone, setTelephone] = useState('')
    const [email, setEmail] = useState('')
    const [postalCode, setPostalCode] = useState(codePostal)
    const [validForm, setValidForm] = useState(false)

    useEffect(() => {
        let validNom = nom !== '';
        let validPrenom = prenom !== '';
        let validTelephone = telephone !== '' && isValidPhoneNumber(telephone, 'FR');
        let validEmail = email !== '' && EmailValidator.validate(email);
        let validPostalCode = postalCode !== '' && codePostalLookup(postalCode) !== undefined;

        setValidForm(validNom && validPrenom && validTelephone && validEmail && validPostalCode);
    }, [nom, prenom, telephone, email, postalCode])

    const submitForm = (e) => {
        e.preventDefault()
        let lieu = codePostalLookup(postalCode);
        fetch(zapierLink, {
            method: 'POST',
            body: JSON.stringify({
                nom: nom,
                prenom: prenom,
                telephone: telephone,
                email: email,
                postalCode: postalCode,
                lieu: lieu
            }),
        })
        .then(response => {
            if (response.status !== 200) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Erreur lors de l\'envoi de votre formulaire',
                    text: 'Veuillez réessayer',
                    willClose: () => {
                        navigate('/contact')
                    }
                })
            }
            return Swal.fire({
                icon: 'success',
                title: 'Nous avons bien reçu votre demande!',
                text: 'Nous vous contacterons dans les plus brefs délais',
                willClose: () => {
                    navigate('/')
                }
            }) 
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Erreur lors de l\'envoi de votre formulaire',
                text: 'Veuillez réessayer',
                willClose: () => {
                    navigate('/contact')
                }
            })
        })
    }

    return (
        <section className="cta-form">
            <div className="form-box">
                <div className="top">
                    <CirclePhoneIcon />
                    <div className="top-text">Si vous voulez, vous pouvez nous appeler au: <a href={`tel:${phoneNumber.split(' ').join('')}`}>{phoneNumber}</a></div>
                </div>
                <div className="bottom">
                    <h3>Veuillez compléter ce formulaire pour qu’on puisse vous faire un devis.</h3>
                    <form onSubmit={submitForm}>
                        <div className="names">
                            <div className="default-input">
                                <label for="nom">Nom</label>
                                <input 
                                    required={true}
                                    name="nom" 
                                    type="text" 
                                    placeholder="Votre nom:"
                                    value={nom}
                                    onChange={e => setNom(e.target.value)}
                                />
                            </div>
                            <div className="default-input">
                                <label for="prenom">Prénom</label>
                                <input 
                                    required={true}
                                    name="prenom" 
                                    type="text" 
                                    placeholder="Votre prénom:"
                                    value={prenom}
                                    onChange={e => setPrenom(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="rest-of-form">
                            <div className="default-input">
                                <label for="telephone">Téléphone</label>
                                <input 
                                    required={true}
                                    name="telephone" 
                                    type="text" 
                                    placeholder="Votre téléphone:"
                                    inputMode="numeric"
                                    value={telephone}
                                    onChange={e => setTelephone(e.target.value)}
                                />
                                <div style={alertStyle}>{telephone && !isValidPhoneNumber(telephone, 'FR') && "Veuillez fourninr un numéro Français valide"}</div>
                            </div>
                            <div className="default-input">
                                <label for="email">Email</label>
                                <input 
                                    required={true}
                                    name="email" 
                                    type="email" 
                                    placeholder="Votre email:"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <div style={alertStyle}>{email && !EmailValidator.validate(email) && "Votre email n'est pas valide"}</div>
                            </div>
                            <div className="default-input">
                                <label for="code-postal">Code postal</label>
                                <input 
                                    required={true}
                                    name="code-postal" 
                                    inputMode="numeric"
                                    type="text" 
                                    placeholder="Votre code postal:"
                                    value={postalCode}
                                    onChange={e => setPostalCode(e.target.value)}
                                    //codePostalLookup[postalCode] !== undefined
                                />
                                <div style={alertStyle}>{(postalCode && codePostalLookup(postalCode) == undefined) && "Votre code postal n'est pas valide"}</div>
                            </div>
                        </div>
                        <div className="submit-div">
                            <div className="submit-btn-wrapper">
                                <button className="submit-btn"  type="submit" disabled={!validForm}>Envoyer</button>
                            </div>
                            <div className="legal-terms">En nous fournissant votre nom, votre numéro de téléphone et votre adresse e-mail, vous consentez à vous contacter par e-mail ou par téléphone pour vous informer de nos services et d'autres informations susceptibles de vous intéresser. Vous pouvez retirer votre autorisation à tout moment en nous contactant.</div>
                        </div>
                    </form>
                </div>
            </div>
       </section>
    ) 
}



export default CTAForm;