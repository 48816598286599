import React, {useState, useEffect} from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import codePostalLookup from '../data/CodePostalLookup';
import CTAIllustration from '../components/CTAIllustration';
import queryString from 'query-string'
import CTAForm from '../components/CTAForm';
import Helmet from 'react-helmet';

const Contact = props =>{
    let postCode  = typeof window !== 'undefined' ? queryString.parse(window.location.search).codepostal : "";
    const [commune, setCommune] = useState('');


    useEffect(() => {
        if (postCode && codePostalLookup(postCode)){
            setCommune(codePostalLookup(postCode).nomCommune)
        }
    } , [commune]);

    return (
        <div className="contact-page">
            <Helmet title="EBNZ - Contact" content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'/>
            <Header />
                <section className="cta-hero">
                    <CTAIllustration className="cta-illustration"/>
                    {
                        commune ? 
                        <h2>Nous fournissons nos services à <span>{commune}</span></h2>
                        :
                        <h2>Contactez-nous</h2>
                    }
                </section>
                <CTAForm codePostal={postCode && codePostalLookup(postCode) ? postCode : ""}/>
            <Footer />
        </div>
    )
}

export default Contact;
